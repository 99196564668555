<template>
  <div>
    <CCard v-if="isDistribution()">
      <CCardHeader>
        <CIcon name="cil-notes"/> 店舗メッセージ送信
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <CSelect
              label="店舗"
              :options="this.storeOptions"
              placeholder="店舗を選択してください。"
              v-model="form.storeId"
              @update:value="onUpdateStore($event)"
              :isValid="checkIfValid('storeId')"
              invalidFeedback="選択してください。"
            />
          </CCol>
          <CCol>
            <CInput
              label="タイトル"
              placeholder=""
              v-model="form.messageTitle"
              :isValid="checkIfValid('messageTitle')"
              invalidFeedback="入力してください。"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CTextarea
              label="本文"
              placeholder=""
              rows="3"
              v-model="form.messageBody"
              :isValid="checkIfValid('messageBody')"
              invalidFeedback="入力してください。"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CButton class="ml-3 mb-3" color="dark" @click="pushMessage()">メッセージ送信</CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            {{ this.resultMessage }}
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <div v-show="false">
      <div id="gray">
        <div id="loading-sp" class="sk-wave">
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import Vue from 'vue';
import firebase from '@firebase/app';
import { uuid } from 'vue-uuid'
 
Vue.use(uuid);

export default {
  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      storeId: {
        required
      },
      messageTitle: {
        required
      },
      messageBody: {
        required
      },
    }
  },
  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      } 
      return !(field.$invalid || field.$model === '')
    },

    validate () {
      this.$v.$touch()
    },

    reset () {
      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    
    getEmptyForm () {
      return {
        storeId: "",
        messageTitle: "",
        messageBody: "",
      }
    },

    async init () {
      // 初期化
      this.form = JSON.parse(JSON.stringify(this.formOrigin))
      this.$store.commit("showLoading")

      let id
      let event
      id = firebase.auth().currentUser.uid
      const userDoc = await firebase.firestore().collection('users').doc(id).get()
      const parent_id = userDoc.data().parent_id
      if (parent_id) {
        id = parent_id
      }
      event = firebase.app().functions('asia-northeast1').httpsCallable('me')

      // ユーザーデータ取得
      event({uid: id}).then(function(res) {
        this.account = res.data.user
        if (this.account.companyCode === undefined) {
          this.account.companyCode = 0
        }
        this.fetchStore()
        this.$store.commit("hideLoading")
      }.bind(this));
    },
    fetchStore() {
      // 店舗情報取得
      firebase.firestore().collection('stores').where('created_user_id', '==', this.account.id).get().then(function(result) {
        let storeOptions = []
        result.forEach(function(doc) {
          const store = doc.data()
          let storeOption = {}
          storeOption['value'] = store.id
          storeOption['label'] = store.name
          storeOption['storeCode'] = store.storeCode
          storeOptions.push(storeOption)
        })
        this.storeOptions = storeOptions.sort((a, b) => Number(a.storeCode) - Number(b.storeCode))
      }.bind(this))
    },
    async pushMessage() {
      this.$v.$touch();
      if (!this.isValid) {
        return;
      }

      var topic = 'stores_' + this.form.storeId;
      const message = {
        notification: {
          title: this.form.messageTitle,
          body: this.form.messageBody
        },
        topic: topic
      };
      const event = firebase.app().functions('asia-northeast1').httpsCallable('sendStoreMessage')
      await event({message: message})
      this.resultMessage = "送信完了しました。"
    },
    isDistribution() {
      return this.account.role == 3
    },
    onUpdateStore(value) {
      this.form.storeId = value
    },
  },

  name: 'Forms',
  data () {
    return {
      storeOptions: [],
      account: {},
      formOrigin: {
        storeId: '',
        messageTitle: '',
        messageBody: '',
      },
      form: {},
      resultMessage: '',
    }
  },
  created () {
    this.init()
  },
  watch: {
    '$route': function () {
      this.init()
    }
  },
}
</script>
